
















import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Image } from "vant";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Image.name]: Image,
  },
})
export default class CloudNav extends Mixins(Mixin) {
  onClickLeft(): void {
    this.$emit("onClickLeft");
  }
}
