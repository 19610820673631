






















import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image, Row } from "vant";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
    ItemTitle,
  },
})
export default class CloudShowItem extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "",
  })
  public img!: ""; //图片
  @Prop({
    type: String,
    default: "",
  })
  public title!: ""; //标题
  @Prop({
    type: String,
    default: "",
  })
  public showStartToEndTime!: ""; //时间段
  goShowDetails(): void {
    this.$emit("goShowDetails");
  }
}
