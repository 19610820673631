
































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Image } from "vant";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";
import CloudVideoItem from "@/views/CloudTheatreDetails/components/CloudVideoItem.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    ItemTitle,
    CloudVideoItem,
  },
})
export default class CloudVideoList extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public videoList!: cloundtheater.VideoDto[];
  leftList: cloundtheater.VideoDto[] = [];
  rightList: cloundtheater.VideoDto[] = [];
  leftHight = 0;
  rightHight = 0;
  @Watch("videoList", { immediate: true, deep: true })
  updateClassify(): void {
    if (this.videoList) {
      this.leftHight = 0;
      this.rightHight = 0;
      this.leftList = [];
      this.rightList = [];
      let data = this.videoList.filter((item, index) => {
        return index < 10;
      });
      this.dealInfo(data);
    }
  }
  dealInfo(allData: cloundtheater.VideoDto[]): void {
    //定义两个临时的变量来记录左右两栏的高度，避免频繁调用setData方法
    let leftH = this.leftHight;
    let rightH = this.rightHight;
    let leftData = [];
    let rightData = [];
    let imgHeight = 460;
    for (let i = 0; i < allData.length; i++) {
      let titleHeight = allData[i].title
        ? Math.ceil(Number(allData[i].title!.length - 1) / 10) * 42
        : 0;
      let couponsLabelHight = allData[i].playCounts ? 28 : 0;

      let currentItemHeight = titleHeight + couponsLabelHight + imgHeight; //当前card整个的高
      if (leftH == rightH || leftH < rightH) {
        //判断左右两侧当前的累计高度，来确定item应该放置在左边还是右边
        leftData.push(allData[i]);
        leftH += currentItemHeight;
      } else {
        rightData.push(allData[i]);
        rightH += currentItemHeight;
      }
    }

    leftData = this.leftList.concat(leftData);
    rightData = this.rightList.concat(rightData);
    //更新左右两栏的数据以及累计高度
    this.leftList = leftData;
    this.rightList = rightData;
    this.leftHight = leftH;
    this.rightHight = rightH;
  }
  goVideoDetails(id?: string): void {
    this.$emit("goVideoDetails", id);
  }
}
