








































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image, Row } from "vant";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
    ItemTitle,
  },
})
export default class CloudVideoItem extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: false,
  })
  public autoplay!: boolean; //是否自动播放
  @Prop({
    type: String,
    default: "",
  })
  public title!: ""; //图片
  @Prop({
    type: String,
    default: "",
  })
  public surfaceImg!: ""; //图片
  @Prop({
    type: String,
    default: "",
  })
  public vide!: ""; //视频
  @Prop({
    type: String,
    default: "",
  })
  public tag!: ""; //视频
  @Prop({
    type: String,
    default: "",
  })
  public showStartToEndTime!: ""; //时间段
  @Prop({
    type: Number,
    default: 0,
  })
  public playCounts!: number;
  dealPlayCounts(playCounts: number): string {
    let num = "";
    if (playCounts < 10000) {
      num = playCounts.toString();
    } else if (10000 <= playCounts && playCounts < 100000000) {
      let number = playCounts / 10000;
      num = number.toFixed(1) + "万";
    } else if (playCounts > 100000000) {
      let number = playCounts / 100000000;
      num = number.toFixed(1) + "亿";
    }
    return num;
  }
  goVideoDetails(): void {
    this.$emit("goVideoDetails");
  }
}
