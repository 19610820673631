
































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { List, Icon, Button, Image, Popup, Field } from "vant";
import CommentItem from "@/components/CommentItem.vue";
@Component({
  components: {
    Mixin,
    [List.name]: List,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Image.name]: Image,
    [Popup.name]: Popup,
    [Field.name]: Field,
    CommentItem,
  },
})
export default class CloudComment extends Mixins(Mixin) {
  @Prop({ default: "", type: String }) public id!: string;
  public praise = false; //是否可以评论
  public headerImg = "";
  public nickName = "";
  public commentList: Array<cms.CloundTheaterCommentResponse> = [];
  public loading = false;
  public page = 1;
  public finished = false;
  public show = false;
  public commentText = "";
  @Watch("id", { immediate: true, deep: true })
  changeProductId(): void {
    this.getComment(true);
  }

  getComment(init = false): void {
    if (init) {
      this.page = 1;
    } else {
      this.page++;
    }
    if (this.id) {
      this.$api.cmsApi.cloundTheaterComment.getCommentPage(
        this.id,
        { page: this.page, pageSize: 10 },
        ({ data }) => {
          this.praise = data.praise || false;
          this.headerImg =
            data.currentUserHeadImg ||
            "https://cdn.polyt.cn/assets/mini_app/background/avator.png";
          if (data.page) {
            if (init) {
              this.commentList = data.page.records!;
            } else {
              this.commentList = this.commentList.concat(data.page.records!);
            }
            this.finished = data.page.pages! > this.page;
          }
        }
      );
    }
  }
  handlePraise(item: cms.CloundTheaterCommentResponse) {
    this.$api.cmsApi.cloundTheaterComment.praise(
      { commentId: item.commentId! },
      ({ data }) => {
        item.likeNum = String(data.likeNum);
        item.like = data.like;
      }
    );
  }
  submitComment() {
    this.$api.cmsApi.cloundTheaterComment.comment(
      {
        commentText: this.commentText,
        videoInfoId: this.id,
      },
      ({ data }) => {
        if (data) {
          this.$toast("评论发送成功，审核通过后即可显示");
          this.show = false;
        }
      }
    );
  }
}
