














import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Image } from "vant";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";
import ProButton from "@/views/ProjectManagement/components/ProButton.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    ItemTitle,
    ProButton,
  },
})
export default class CloudIntroduce extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "",
  })
  public title!: string; //标题
  @Prop({
    type: String,
    default: "",
  })
  public introduceContent!: string; //内容
  isShow = false;
  buttonInfo = { upward: false };
  dealContent(introduceMessage: string): string {
    let text = "";
    if (introduceMessage.includes("<img")) {
      text = introduceMessage.replace(/<img /g, '<img class="rich-img" ');
    } else {
      text = introduceMessage;
    }
    return text;
  }
  dealInfo(): void {
    let height = (document.getElementById("introduce") as HTMLElement)
      .offsetHeight;
    if (height <= 50) {
      this.isShow = false;
      this.buttonInfo.upward = true;
    } else {
      this.isShow = true;
      this.buttonInfo.upward = false;
    }
  }
}
