
















import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Image } from "vant";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    ItemTitle,
  },
})
export default class CloudAnthology extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public videoEpisodes!: cloundtheater.VideoEpisodeDto[]; //视频福标题
  activeIndex = 0;
  onClickLeft(): void {
    this.$emit("onClickLeft");
  }
  selectVideo(index: number, data: cloundtheater.VideoEpisodeDto): void {
    this.activeIndex = index;
    this.$emit("selectVideo", data);
  }
}
