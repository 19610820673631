



























































import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Swipe, SwipeItem, Image, ImagePreview, Row, Icon } from "vant";

import CloudAnthology from "@/views/CloudTheatreDetails/components/CloudAnthology.vue";
import { videoPlayer } from "vue-video-player"; //导入组件
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
//首先引入微信jsdk
import WeixinJSBridge from "weixin-js-sdk";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Row.name]: Row,
    [Icon.name]: Icon,
    videoPlayer,
    CloudAnthology,
  },
})
export default class CloudVideo extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: false,
  })
  public autoplay!: boolean; //是否自动播放
  @Prop({
    type: Boolean,
    default: false,
  })
  public paid!: boolean; //当前用户是否已付费(true:已付费) ,
  @Prop({
    type: Boolean,
    default: false,
  })
  public pay!: boolean; //是否是收费视频 ,
  @Prop({
    type: Number,
    default: null,
  })
  public price!: number; //价格
  @Prop({
    type: String,
    default: "",
  })
  public surfaceImg!: string; //视频封面
  @Prop({
    type: String,
    default: "",
  })
  public vide!: string; //视频
  @Prop({
    type: String,
    default: "",
  })
  public advertType!: string; //贴片广告类型
  @Prop({
    type: Number,
    default: 0,
  })
  public showTime!: number; //贴片播发时间
  @Prop({
    type: Array,
    default: [],
  })
  public patchInfoDtoList!: Array<cloundtheater.patchInfoDto>;
  @Prop({
    type: String,
    default: "",
  })
  public videoid!: string; //视频id

  showPay = false;
  flag = 0; // 记录播放位置
  playerOptions: any = {
    playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
    autoplay: false, // 如果为true,浏览器准备好时开始回放。
    muted: false, // 默认情况下将会消除任何音频。
    loop: false, // 是否视频一结束就重新开始。
    preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
    language: "zh-CN",
    aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
    sources: [
      {
        type: "video/mp4", // 类型
        src: "", // url地址
      },
    ],
    poster: "", // 封面地址
    notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
    controls: true,
    controlBar: {
      timeDivider: true, // 当前时间和持续时间的分隔符
      durationDisplay: true, // 显示持续时间
      remainingTimeDisplay: false, // 是否显示剩余时间功能
      currentTimeDisplay: true, // 当前时间
      volumeControl: true, // 声音控制键
      progressControl: true, // 进度条
      playToggle: true, // 暂停-播放 按钮
      fullscreenToggle: true, // 是否显示全屏按钮
    },
  };
  // 图片倒计时
  countdown = 0;
  timer: any = {};
  public current = "0";
  public patchEng = true;
  public fullscreen = false;
  myPlayer: any;
  get playsinline(): boolean {
    return this.isIos();
  }
  @Watch("advertType")
  updateAdvertType(): void {
    this.initData();
  }
  @Watch("$route", { immediate: true, deep: true })
  updateRouter(): void {
    this.initData();
  }
  @Watch("patchEng", { immediate: true, deep: true })
  updatepatchEng(): void {
    if (this.patchEng) {
      this.dealUrl(this.vide, this.surfaceImg, this.autoplay);
      this.getlog({
        videoId: this.videoid,
      });
    }
  }

  created(): void {
    this.initData();
  }
  mounted(): void {
    let videoPlayer = this.$refs.videoPlayer as any;
    if (this.advertType == "01") {
      document.addEventListener("touchstart", function myListener() {
        videoPlayer.player.play();
        document.removeEventListener("touchstart", myListener);
      });
    }
  }
  isIos(): boolean {
    // let u = navigator.userAgent;
    // // let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    // let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //
    // if (isIOS) {
    //   return false;
    // } else {
    return true;
    // }
  }

  initData(): void {
    if (this.advertType == "01" && this.patchInfoDtoList.length) {
      // 贴片广告是 视频
      this.patchEng = false;
      this.dealPatchUrl();
      this.$nextTick(() => {
        this.getVideos();
      });
      // this.getvideotime();
    } else if (this.advertType == "02" && this.patchInfoDtoList.length) {
      // 贴片广告是图
      this.patchEng = false;
      this.startRain();
    } else {
      this.dealUrl(this.vide, this.surfaceImg, this.autoplay);
    }
    // this.$nextTick(() => {
    if (this.patchInfoDtoList.length == 1) {
      this.getlog({
        patchAdvertId: this.patchInfoDtoList[0].patchAdvertId,
        videoId: this.videoid,
      });
    }
    // });
  }
  getlog(params: any): void {
    this.$api.cloundtheaterApi.cloundTheaterVideo.videoViewingLog(params);
  }
  closepatch(): void {
    // 广告视频时
    this.patchEng = true;
    if (this.advertType == "01") {
      this.dealUrl(this.vide, this.surfaceImg, this.autoplay);
    } else {
      this.countdown = 0;
      clearInterval(this.timer);
      this.dealUrl(this.vide, this.surfaceImg, this.autoplay);
    }
  }
  getvideotime(): void {
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     let du: any = document.getElementById("vjs_video_444_html5_api"); //获取组件下的video
    //     var second = parseInt(du.duration % 60);
    //     // return result //转化成分，秒
    //   }, 200);
    // });
    this.patchInfoDtoList.forEach((item) => {
      var url = URL.createObjectURL(item);
      var audioElement: any = new Audio(url);
      var result = 0;
      audioElement.addEventListener("loadedmetadata", function () {
        // 视频时长值的获取要等到这个匿名函数执行完毕才产生
        result = result + parseInt(audioElement.duration); //得到时长为秒，小数，182.36
      });
      this.countdown = result;
      console.log(result);
    });
  }
  formatTimeStr(val: string | number): string | number {
    if (Number(val) > 9) {
      return val;
    } else {
      return "0" + val;
    }
  }
  timeupdate(e: { target: { currentTime: number } }): void {
    if (e.target.currentTime >= 2) {
      if (this.paid === false) {
        let vide = document.getElementById("video");
        (vide as HTMLVideoElement).pause();
        this.showPay = true;
      }
    }
  }
  goPay(): void {
    this.$emit("goPay");
  }

  dealUrl(url: string, img: string, autoplay: boolean): void {
    this.playerOptions.playbackRates = [0.5, 1.0, 1.5, 2.0];
    this.playerOptions.autoplay = autoplay;
    this.playerOptions.muted = autoplay;
    this.playerOptions["sources"][0]["src"] = url;
    this.playerOptions.poster = img;
    this.playerOptions.controlBar.timeDivider = true;
    this.playerOptions.controlBar.durationDisplay = true;
    this.playerOptions.controlBar.remainingTimeDisplay = false;
    this.playerOptions.controlBar.currentTimeDisplay = true;
    this.playerOptions.controlBar.volumeControl = false;
    this.playerOptions.controlBar.progressControl = true;
    this.playerOptions.controlBar.playToggle = true;
  }
  // 获取视频列表
  getVideos(): void {
    this.playerOptions["sources"][0]["src"] =
      this.patchInfoDtoList[0].patchAdvertUrl;
    // this.autoplayVideo();
  }
  autoplayVideo(): void {
    let videoplayer = this.$refs.videoPlayer as HTMLFormElement;
    //调用 <audio> 元素提供的方法 play()
    videoplayer.player.play();
    //判斷 WeixinJSBridge 是否存在
    if (
      typeof WeixinJSBridge == "object" &&
      typeof WeixinJSBridge.invoke == "function"
    ) {
      videoplayer.play();
    } else {
      //監聽客户端抛出事件"WeixinJSBridgeReady"
      if (document.addEventListener) {
        document.addEventListener(
          "WeixinJSBridgeReady",
          () => {
            videoplayer.player.play();
          },
          false
        );
      }
    }
    //voiceStatu用來記録狀態,使 touchstart 事件只能觸發一次有效,避免與 click 事件衝突
    var voiceStatu = true;
    //监听 touchstart 事件进而调用 <audio> 元素提供的 play() 方法播放音频
    document.addEventListener(
      "touchstart",
      (e) => {
        if (voiceStatu) {
          videoplayer.player.play();
          voiceStatu = false;
        }
      },
      false
    );
  }
  // 视频播完回调
  onPlayerEnded(): void {
    // 下标 +1
    this.flag += 1;
    if (!this.patchEng) {
      // 放完从第一个播放 否则直接播放下一个
      if (this.flag + 1 > this.patchInfoDtoList.length) {
        this.patchEng = true;
      } else {
        this.playerOptions["sources"][0]["src"] =
          this.patchInfoDtoList[this.flag].patchAdvertUrl;
        this.getlog({
          patchAdvertId: this.patchInfoDtoList[Number(this.flag)].patchAdvertId,
          videoId: this.videoid,
        });
      }
    }
  }
  dealPatchUrl(): void {
    this.playerOptions.playbackRates = [];
    this.playerOptions.autoplay = true;
    this.playerOptions.muted = true;
    this.playerOptions.controlBar.timeDivider = false;
    this.playerOptions.controlBar.durationDisplay = false;
    this.playerOptions.controlBar.remainingTimeDisplay = false;
    this.playerOptions.controlBar.currentTimeDisplay = false;
    this.playerOptions.controlBar.volumeControl = false;
    this.playerOptions.controlBar.progressControl = false;
    this.playerOptions.controlBar.playToggle = false;
  }
  onChange(index: string): void {
    this.current = index;
    this.getlog({
      patchAdvertId: this.patchInfoDtoList[Number(index)].patchAdvertId,
      videoId: this.videoid,
    });

    // 播放完贴片广告
  }
  clickPatch(): void {
    if (
      this.advertType == "01" &&
      !this.patchEng &&
      this.flag + 1 <= this.patchInfoDtoList.length
    ) {
      let list: any = this.patchInfoDtoList[this.flag];
      this.clickPatchAdvertUrl(list.relevanceJumpLinkDto);
    }
  }
  clickPatchAdvertUrl(
    image: cloundtheater.relevanceJumpLinkDto | undefined
  ): void {
    if (!image) {
      return;
    }
    this.goToJump(image.keyWord, image.linkId, image.linkType, image.linkUrl);
  }
  startRain(): void {
    if (this.advertType == "02") {
      this.countdown = (this.patchInfoDtoList.length * this.showTime) / 1000;
    }
    let count = this.countdown;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    let timer = setInterval(() => {
      count = count - 1;
      if (count <= 0) {
        self.patchEng = true;
        clearInterval(timer);
        return;
      }
    }, 1000);
  }
  fullScreenimg(): void {
    let imgPlayer = document.getElementById("imgPlayer") as HTMLFormElement;
    if (this.fullscreen) {
      // 退出全屏
      this.ExitFullscreen();
    } else {
      let target = document.getElementById("videoPlayer") as HTMLFormElement;
      this.fullScreen1(imgPlayer);
      // this.fullScreen1(target);
    }
    this.fullscreen = !this.fullscreen;
  }
  ExitFullscreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
  fullScreen1(target: HTMLFormElement): void {
    if (target.requestFullscreen) {
      target.requestFullscreen();
    }
    if (target.webkitRequestFullscreen) {
      target.webkitRequestFullscreen();
    }
    if (target.mozRequestFullScreen) {
      target.mozRequestFullScreen();
    }
    if (target.msRequestFullscreen) {
      target.msRequestFullscreen();
    }
  }
}
